/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


// 获取配置
export const getConfig = data => {
  return api({
    url: 'admin/shop/settings/shopConfig',
    method: 'post',
    data
  });
};
// 设置配置
export const setConfig = data => {
  return api({
    url: 'admin/shop/settings/saveShopConfig',
    method: 'post',
    data
  });
};
// 过滤词库
export const getFilterWords = data => {
    return api({
      url: '/admin/portal/article/allWords',
      method: 'post',
      data
    })
  }
