<template>
  <div class="list-content" v-loading="loading">
    <p class="save-title" style="margin-top: 4px">客服设置</p>
    <div class="text-box">
      <div class="text">
        小程序客服，仅在小程序向客户提供服务，<el-link
          href="https://developers.weixin.qq.com/miniprogram/introduction/custom.html#%E5%A1%AB%E5%86%99%E6%B6%88%E6%81%AF%E6%8E%A8%E9%80%81%E9%85%8D%E7%BD%AE"
          :underline="false"
          target="_brank"
          type="primary"
          >查看开启指引</el-link
        >
      </div>
      <!-- <div class="text">
        微信客服，可同时在小程序、H5网页向客户提供服务，<el-link
        href="https://work.weixin.qq.com/nl/index/wxconnection?category=wxservicer&from=mini&st=907AFB0ECAA4969786F512633EF0D58A2C1ECFE7ACCA3BEFD2CF68956924BCAD340BB2EB3A5BCCFECC4988BCAF17B6A3AD55236E7624796EC45390AE715C201FF30B5F8E34BC0000C084C19CE987A3BD79590A6EC7D49D61CF3465D194FC0A787E4CA919C15160BFA73F12C8FADD03403DAF0ACEE2BC3DFAF8ABE247C94412FA1654A62D24BAA91FF9C9F36A81E71594BC11117462D650A1854FC7DDE3D5C3F4471DB9190FC2E049D19899C75479D8C517A8B3C53ACED9B70F1296AC7E815BB795BD1C1DCB826C815BC0407AAB23792025B1D4B7C647C75A0A00B83969344F3613CB0CEFE17E69CAE1178C7FA72199E2&vid=1688854804393496&cst=1D88417EEB9EBDA07F0A8BE6808DC1B7547FFEC1C6D9E76F97F9F14EF933028C5B0B77625E52D87E11243734BC890B03&deviceid=8bac9b9c-ccc0-4f9e-8995-714ba4e3d3fa&version=4.0.16.90619&platform=mac"
          :underline="false"
          type="primary"
          >查看开启指引</el-link
        >
      </div> -->
    </div>
    <!-- 先隐藏 -->
    <template v-if="false">
      <p class="save-title" style="margin-top: 4px">商城送礼设置</p>

      <el-form class="save-content" label-width="82px">
        <el-form-item label="送礼功能：">
          <el-switch
            v-model="config.gift_status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <p class="save-title" style="margin-top: 4px">商品评价设置</p>
      <el-form
        size="medium"
        :model="config"
        label-position="right"
        label-width="70px"
        class="form small-form"
        @submit.native.prevent
      >
        <el-form-item label="审核设置：">
          <el-radio-group v-model="config.comment_check_type">
            <el-radio :label="1">先发后审</el-radio>
            <el-radio :label="0">先审后发</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="过滤词库：">
          <el-radio-group v-model="config.is_open_filter_word">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择词库：" v-if="config.is_open_filter_word">
          <el-radio-group v-model="isSome">
            <el-radio :label="false">全部</el-radio>
            <el-radio :label="true">部分</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" v-if="isSome && config.is_open_filter_word">
          <el-select
            v-model="config.filter_words"
            placeholder="请选择词库"
            multiple
          >
            <el-option
              v-for="item in all_filter_words"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getConfig, setConfig, getFilterWords } from "../api/shop-config";
import FixedActionBar from "@/base/layout/FixedActionBar";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

export default {
  components: { FixedActionBar, SingleMediaWall },
  data() {
    return {
      loading: false,
      config: {},
      all_filter_words: [],
      isSome: false,
    };
  },
  watch: {
    isSome: {
      handler(val) {
        if (!val) {
          this.config.filter_words = [];
        }
      },
    },
  },
  created() {
    this.getDatail();
    this.getFilterWords();
  },
  methods: {
    getDatail() {
      this.loading = true;
      getConfig()
        .then((res) => {
          this.config = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveConfig() {
      this.loading = true;
      setConfig({ config: this.config })
        .then((res) => {
          this.$message.success(res.msg);
          this.getDatail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getFilterWords() {
      getFilterWords()
        .then((res) => {
          this.all_filter_words = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.save-title {
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-left: 3px #3479ef solid;
  line-height: 16px;
  margin-bottom: 19px;
}

.save-content {
  padding: 12px 4px 10px;

  .el-form-item__label,
  ::v-deep.el-form-item__label {
    white-space: nowrap;
  }
  .el-input {
    max-width: 295px;
  }
}
.text-box {
  margin-bottom: 30px;
  .text {
    margin-bottom: 10px;
    font-size: 14px;
  }
}
</style>
